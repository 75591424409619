import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import  './OffersonCard.scss';

const OffersonCardslidersettings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 2,
    autoplay: false,
    autoplaySpeed: 5000,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 3
            }
        },
        {
            breakpoint: 480,
            settings: {
                centerMode: true,
                slidesToShow: 1
            }
        }
    ]
};

class OffersonCard extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className="credit-card-offers">
                    {/* <!--Slider Item starts--> */}
                    <Slider {...OffersonCardslidersettings}>
                        <div className="item">
                            <div className="card-details">
                                <img src={require("../../../assets/images/offers/cards/american-express.webp")} alt="Amex - Credit Card" />
                                <h3>Amex - Credit Card</h3>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et </p>
                                <Link>View Details</Link>
                            </div>
                        </div>
                        {/* item ends */}
                        <div className="item">
                            <div className="card-details">
                                <img src={require("../../../assets/images/offers/cards/visa.webp")} alt="Visa cards" />
                                <h3>Visa cards</h3>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et </p>
                                <Link>View Details</Link>
                            </div>
                        </div>
                        {/* item ends */}
                        <div className="item">
                            <div className="card-details">
                                <img src={require("../../../assets/images/offers/cards/hsbc.webp")} alt="Hsbc credit card" />
                                <h3>Hsbc credit card</h3>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et </p>
                                <Link>View Details</Link>
                            </div>
                        </div>
                        {/* item ends */}
                        <div className="item">
                            <div className="card-details">
                                <img src={require("../../../assets/images/offers/cards/hdfc.webp")} alt="hdfc credit cards" />
                                <h3>hdfc credit cards</h3>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et </p>
                                <Link>View Details</Link>
                            </div>
                        </div>
                        {/* item ends */}
                        <div className="item">
                            <div className="card-details">
                                <img src={require("../../../assets/images/offers/cards/hdfc.webp")} alt="hdfc credit cards" />
                                <h3>hdfc credit cards</h3>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et </p>
                                <Link>View Details</Link>
                            </div>
                        </div>
                        {/* item ends */}
                        <div className="item">
                            <div className="card-details">
                                <img src={require("../../../assets/images/offers/cards/hdfc.webp")} alt="hdfc credit cards" />
                                <h3>hdfc credit cards</h3>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et </p>
                                <Link>View Details</Link>
                            </div>
                        </div>
                        {/* item ends */}

                        

                    </Slider>
                    {/* <!--Slider Item ends--> */}
                </div>

            </React.Fragment>
        )
    }
}
export default withRouter(OffersonCard)